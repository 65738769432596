<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="650"
    max-height="95%"
  >
    <v-card class="modal-card">
      <div class="header-modal mb-4">
        <div class="title-modal">
          <div class="headline-l">{{ textTitleModal }}</div>
        </div>
        <button class="btn-close" @click="$emit('close')">
          <v-icon class="icon-close">
            mdi-close
          </v-icon>
        </button>
      </div>
      <div>
        <!-- <div class="headline-s mb-2"> -->
        <!-- รายละเอียดเงื่อนไขการส่งเสริมการขาย -->

        <!-- <template v-if="isDeposit">
              สั่งจองแบบมัดจำ รับสินค้าที่สาขาเท่านั้น
            </template>
            <template v-else>
              สั่งจองแบบชำระจำนวนเต็ม จัดส่งตามที่อยู่จัดส่งเท่านั้น
            </template> -->
        <!-- </div> -->
        <div class="term-content">
          <div v-if="conditionHtml" v-html="conditionHtml"></div>
        </div>
      </div>

      <div class="footer-modal">
        <div class="term-action">
          <div class="agree-container">
            <v-checkbox
              class="mt-0 label-l-semibold"
              :input-value="agreeTerm"
              :true-value="true"
              :color="color['color-bnn']"
              hide-details
              @click="agreeTerm = !agreeTerm"
            >
              <template v-slot:label>
                <span class="text-black">
                  ฉันรับทราบว่าฉันได้อ่านและยอมรับ
                  <span
                    @click.stop="openLink"
                    class="text-label-term"
                  >
                    ข้อกำหนดและเงื่อนไข
                  </span>
                  การสั่งซื้อสินค้าเรียบร้อยแล้ว
                </span>
              </template>
            </v-checkbox>
          </div>

          <!-- <div class="info-container">
            <div v-if="!isDeposit">
              <v-icon>mdi-information-outline</v-icon>
              โปรดตรวจสอบรายการชำระเงินเต็มจำนวนสินค้าให้เรียบร้อย
              ท่านจะได้รับข้อมูลรายละเอียด ตามที่ระบุใน Email หรือ SMS
              , Line
            </div>
            <div v-else>
              <v-icon>mdi-information-outline</v-icon>
              โปรดตรวจสอบรายการชำระเงินค่ามัดจำสินค้าให้เรียบร้อย
              ท่านสามารถชำระยอดที่เหลือที่สาขาที่เลือก ตามที่ระบุใน
              Email หรือ SMS , Line ที่ได้รับ
            </div>
          </div> -->

          <div class="action-container mt-md-4">
            <div class="price-container">
              <!-- <template v-if="isDeposit">
                <div class="price-box">
                  <div class="text-price-md">ยอดรวมชำระมัดจำ</div>
                  <span class="text-price-lg text-right text-green">
                    ฿{{ productPriceDeposit | currency2 }}
                  </span>
                </div>
                <div class="price-box">
                  <div class="text-price-md">
                    ยอดคงเหลือที่ต้องชำระ
                  </div>
                  <span class="text-price-lg text-right">
                    ฿{{ remainAmount | currency2 }}
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="price-box">
                  <div class="text-price-md">ยอดรวมสุทธิ</div>
                  <span class="text-price-lg text-right text-green">
                    ฿{{ netPriceSummary | currency2 }} .-
                  </span>
                </div>
              </template> -->
            </div>
            <div class="btn-container">
              <v-btn
                block
                depressed
                :color="color['color-bnn']"
                :dark="!isBnn && agreeTerm"
                :disabled="!agreeTerm"
                @click="$emit('agree')"
              >
                {{ labelButtonConfirm }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import color from '@/config/color'
import isBnn from '@/config/isBnn'
import branchTypes from '@/config/branchTypes'

export default {
  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
      branchType: state => state.branchStore.branchType,
      cartSummaryData: state => state.cart.cartSummaryData,
    }),

    conditionHtml() {
      return this.$route.query.code
        ? this.campaign?.data?.condition_html_stockcard ??
            this.campaign?.data?.condition_html
        : this.campaign?.data?.condition_html
    },

    linkTerm() {
      if (isBnn) {
        return 'https://www.bnn.in.th/th/pages/terms-of-use'
      }
      return 'https://www.studio7thailand.com/th/pages/terms-of-use'
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    textTitleModal() {
      //   if (this.isDeposit) {
      //     return 'สั่งจองแบบชำระมัดจำ'
      //   }
      //   return 'เงื่อนไข สั่งซื้อล่วงหน้า'
      return 'เงื่อนไขรายการส่งเสริมการขาย'
    },

    productPriceDeposit() {
      return this.cartSummaryData?.product_price_deposit_summary ?? 0
    },

    netPriceSummary() {
      // Notes: Previous code is cartSummaryData.total_price
      return this.cartSummaryData?.final_price_original ?? 0
    },

    remainAmount() {
      // Notes: Previous code is cartSummaryData.remain_amount
      return this.cartSummaryData?.remain_amount_original ?? 0
    },

    campaignType() {
      return process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'
    },

    labelButtonConfirm() {
      //   if (this.isDeposit) {
      //     return `ต่อไป (ชำระมัดจำ ฿${this.productPriceDeposit})`
      //   }
      return 'ยืนยัน'
    },
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      agreeTerm: false,
      color,
      branchTypes,
      isBnn,
    }
  },

  watch: {
    show(val) {
      if (val) {
        this.agreeTerm = false
      }
    },
  },

  components: {},

  methods: {
    openLink() {
      window.open(this.linkTerm, '_blank')
    },

    goContact() {
      this.campaignType === 'BNN'
        ? window.open(
            'https://www.facebook.com/Bananaitshop/',
            '_blank',
          )
        : window.open(
            'https://www.facebook.com/Studio7Thailand/',
            '_blank',
          )
    },
  },
}
</script>

<style lang="stylus" scoped>
.text-desc {
    font-size: 0.9rem
    color: $color-bnn-gray-medium-2
}

.title-modal {
    display: flex
    justify-content:flex-start
    flex-direction: column
}

.term-content
    background-color: $color-bnn-gray-light-2
    width: 100%
    height: 100%
    max-height: 40dvh
    overflow: auto
    border-radius: 4px
    padding: 1rem
    @media small
      padding: 1rem
    @media medium
      padding: 1rem


.term-action {
    display: flex
    flex-direction: column
    width: 100%
}

.text-label-term
  color: $color-bnn
  text-decoration: underline;
  margin: 0 1px

.btn-cancel
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.text-cancel
  color: $color-secondary-text

.price-wrapper
  display: grid
  grid-template-columns: repeat(2, 1fr)
  padding: 8px 0
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .text-price-md
    font-size: 1rem
    text-overflow: ellipsis
  .text-price-lg
    font-size: 1.125rem
    font-weight: bold
    text-overflow: ellipsis
.footer-modal
  font-size: 0.875rem
  color: rgba(0, 0, 0, 0.6)
  padding: 0
  width: 100%
  margin-top: 1rem

.is-mobile-none
  @media small
    display: none !important
.is-desktop-none
  display: none
  @media small
    display: block !important
.close
  display: flex
  justify-content: flex-end
  position: absolute
  right: 1rem
  top: 1rem

.v-card__actions
  margin-top: -1rem;

.link
    color: #1976d2
    cursor: pointer
.text-price-lg
	font-size: 20px
	font-weight: bold
	text-overflow: ellipsis

.action-container
	display: flex
	align-items: flex-end
	justify-content: flex-end
	flex-direction: column
	gap: 20px
	// padding: 20px
	// border-top: 1px solid #f0f0f0
	@media min-medium
		flex-direction: row
	.price-container
		display: grid
		align-items: center
		grid-template-columns: repeat(1, 1fr)
		gap: 8px
		width: 100%
		@media min-medium
			gap: 0
			grid-template-columns: repeat(2, 1fr)
		.price-box
			display: flex
			justify-content: space-between
			align-items: center
			flex-flow: row wrap
			@media min-medium
				flex-flow: column wrap
				align-items: flex-start
		.price-box:nth-child(2)
			border-right: none
			@media min-medium
				padding-left: 20px
				border-left: 1px solid #f0f0f0\

	.btn-container
		width: fit-content
		max-width: 100%
.info-container
	font-size: 12px
	.v-icon
		font-size: 12px
		color: #808080


.modal-card {
    padding: 24px
    border-radius: 18px
}

.header-modal {
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px
}

.btn-close {
    width: 40px;
    height: 40px;
    min-width 40px

    background: #e8e8e8;
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }
}
</style>
