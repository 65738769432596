<template>
  <div>
    <Load v-if="isLoading" />
    <template v-if="!isLoading && campaignStatus === 'active'">
      <template v-if="isBnn">
        <div class="product-detail">
          <!-- 
            TODO : Remove this banner
            <Banner
            v-if="stickyBanner"
            :image="stickyBanner"
            :link="stickyLink"
          /> -->
          <v-container>
            <!-- Content -->
            <div class="product-detail-layout gallery-square-layout">
              <div class="gallery pdp-preview-swiper">
                <template v-if="thumbnailList.length > 0">
                  <PreviewImageSwiper
                    loop
                    showThumbnail
                    allowTouchMove
                    :medias="thumbnailList"
                    :key="keyPreview"
                  />
                </template>
              </div>

              <div
                class="pdp-content"
                :class="{ 'select-by-sku': isFromQRCode }"
              >
                <!-- Countdown -->
                <v-row
                  v-if="timeLeft > -1"
                  v-bind="{ justify: 'start' }"
                >
                  <v-col class="is-desktop">
                    <h3 class="hurry-up-label">
                      สั่งซื้อล่วงหน้า! เริ่ม xx กันยายน 2567
                    </h3>
                    <div class="my-4">
                      <div class="is-desktop">
                        <CountDown
                          v-if="timeLeft > -1"
                          v-bind="{
                            size: 'md',
                            justify: 'flex-start',
                          }"
                          :timeLeft="timeLeft"
                          @end="reload"
                        />
                      </div>
                    </div>
                    <div class="my-2 hurry-up-label">
                      <span class="font-bold"
                        >สิ้นสุดการสั่งซื้อสินค้า :
                      </span>
                      {{ endDateTime | fullDataTh }}
                      เวลา {{ endTime }}
                    </div>
                  </v-col>

                  <div class="is-mobile">
                    <CountDown
                      v-if="timeLeft > -1"
                      v-bind="{ size: 'md', justify: 'flex-start' }"
                      :timeLeft="timeLeft"
                      @end="reload"
                    />
                  </div>
                </v-row>
                <!-- End Countdown -->

                <!-- Model -->
                <v-row>
                  <v-col cols="12">
                    <div class="campaign-name-content">
                      <h1>{{ campaignName }}</h1>
                      <span class="text-label">
                        รหัสสินค้า : {{ sku }}
                      </span>
                    </div>
                    <div
                      v-if="showFreeBie"
                      class="product-label-layout"
                    >
                      <LabelProduct
                        v-if="isShowLabelFreebie"
                        labelType="free"
                        title="รับฟรีของแถม"
                        remark="ขอสงวนสิทธิ์ในการเปลี่ยนแปลงของแถมโดยไม่ต้องแจ้งให้ทราบล่วงหน้า"
                        :data="freebieItems"
                        showMore
                      />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div
                      v-if="previewHtml"
                      v-html="previewHtml"
                      class="preview-html"
                    ></div>
                    <div
                      v-if="campaign.data.order_limit > 0"
                      class="text-red"
                    >
                      <div>
                        สงวนสิทธิ์ในการสั่งซื้อ 1 ท่าน /
                        {{ campaign.data.order_limit }} เครื่อง / 1
                        เบอร์โทร / 1 อีเมล เท่านั้น
                      </div>
                      <div>
                        บริษัทจะขอยกเลิกออเดอร์หากตรวจพบว่าท่านได้ทำการสั่งจองเกินสิทธิ์แล้ว
                      </div>
                    </div>
                  </v-col>
                  <div class="model-wrapper">
                    <v-col cols="12">
                      <span class="text-label">Choose Model : </span>
                      <span class="font-bold">{{ modelName }}</span>
                    </v-col>
                    <v-col cols="12" class="model-container">
                      <ButtonModel
                        v-for="(item, index) in modelItems"
                        @click="
                          isFromQRCode && isDisabled(item, 'model')
                            ? null
                            : onClickModel(item)
                        "
                        :disabled="isDisabled(item, 'model')"
                        :select="item === model"
                        :title="item.name"
                        :price="item.start_price"
                        :key="index"
                      />
                    </v-col>
                  </div>
                </v-row>
                <!-- End Model -->

                <!-- Color -->

                <v-row>
                  <div class="color-wrapper">
                    <v-col cols="12">
                      <span class="text-label">Choose Color : </span>
                      <span class="font-bold">{{
                        selectColorName
                      }}</span>
                    </v-col>
                    <v-col cols="12">
                      <div class="color-content">
                        <ButtonColor
                          v-for="(item, index) in colorItems"
                          @click="
                            isFromQRCode && isDisabled(item, 'color')
                              ? null
                              : onClickColor(item)
                          "
                          :disabled="isDisabled(item, 'color')"
                          :color="item.color_hex"
                          :height="45"
                          :width="45"
                          :select="item === selectColor"
                          :key="index"
                        >
                        </ButtonColor>
                      </div>
                    </v-col>
                  </div>
                </v-row>

                <!-- End Color -->

                <!-- Capacity -->

                <v-row>
                  <div class="capacity-wrapper">
                    <v-col cols="12">
                      <span class="text-label">
                        Choose Capacity :
                        <span
                          v-if="!selectColor"
                          class="text-red pb-2"
                        >
                          * กรุณาเลือก{{ titleTextCapacity }}
                        </span>
                      </span>
                      <span class="font-bold">
                        {{ selectCapacityName }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <div class="capacity-content">
                        <template v-if="selectColor">
                          <ButtonSpec
                            v-for="(item, index) in storageItems"
                            @click="
                              isFromQRCode &&
                              isDisabled(item, 'capacity')
                                ? null
                                : onClickCapacity(item)
                            "
                            :disabled="isDisabled(item, 'capacity')"
                            :title="item.size"
                            :select="item === selectSKU"
                            :key="index"
                          />
                        </template>
                        <template v-else>
                          <v-skeleton-loader
                            v-for="(item, index) in [0, 1, 2]"
                            type="button"
                            :key="index"
                          ></v-skeleton-loader>
                        </template>
                      </div>
                    </v-col>
                  </div>
                </v-row>

                <!-- End Capacity -->

                <hr class="my-2 gray desktop-only" />

                <div
                  v-if="hasAlSoBought && !isFromStockCard"
                  class="title-also-bought"
                >
                  Also Bought
                </div>
                <!-- Apple Care -->
                <!-- <AppleCare
                  v-if="appleCare && appleCare.active"
                  @change="checkSummary"
                  is-button
                /> -->
                <!-- End Apple Care -->

                <!-- Accessory Bundles -->
                <div
                  v-if="
                    accessoryBundles.length > 0 && !isFromStockCard
                  "
                  class="py-4"
                >
                  <AccessoryBundle
                    v-for="(accessory, index) in accessoryBundles"
                    :key="index"
                    :accessoryBundle="accessory"
                    @change="checkSummary"
                    is-button
                  />
                </div>
                <!-- End  Accessory Bundles -->

                <!-- Bundle -->
                <v-row v-if="isShowSwiperBundle && !isFromStockCard">
                  <v-col cols="12">
                    <span class="text-label">
                      Save With Bundle :
                    </span>
                    <span class="font-bold">
                      {{ bundleList }}
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <template
                      v-if="bundlesItemsNoFreebie && !isFromStockCard"
                    >
                      <BundleSwiper
                        @click="onClickSelectBundle"
                        :data="bundlesItemsNoFreebie"
                        key="bundle"
                      />
                    </template>
                  </v-col>
                </v-row>
                <!-- End Bundle -->

                <!-- ShipDate -->
                <div ref="cartShip"></div>
                <div
                  class="cart-ship-wrapper mt-0 mt-md-4"
                  :class="cartClass"
                >
                  <v-row class="no-gutters">
                    <v-col cols="12" class="my-2" v-if="isSoldOut">
                      <div>
                        <h3 class="text-bnn-red-2">สินค้าหมด</h3>
                      </div>
                      <div>
                        Please visit our store or contact support to
                        check the availability.
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="wrap-btn-action">
                        <!-- <div class="w-full" v-if="isSoldOut">
                          <template>
                            <div class="soldOut">
                              <Button
                                block
                                depressed
                                :disabled="disableAction"
                                color="color-bnn-opacity-90"
                                title="สินค้าหมด"
                              />
                            </div>
                          </template>
                        </div> -->
                        <template>
                          <div class="wrap-btn-action-case">
                            <div
                              v-if="isShowBtnDeposit"
                              class="btn-action-item"
                              :class="{ full: !isShowBtnFullPayment }"
                            >
                              <div class="deposit">
                                <v-btn
                                  @click="onClickDeposit"
                                  id="btn-deposit"
                                  class="btn-deposit"
                                  block
                                  depressed
                                  large
                                  :outlined="isShowBtnFullPayment"
                                  :height="
                                    !isShowBtnFullPayment
                                      ? '60px'
                                      : '48px'
                                  "
                                  :color="
                                    !isSoldOut
                                      ? colorBnn['color-bnn']
                                      : 'color-bnn-opacity-90'
                                  "
                                  :disabled="
                                    isSoldOut ||
                                      isDisabled(
                                        selectSKU,
                                        'capacity',
                                      )
                                  "
                                >
                                  <div class="deposit-content">
                                    <div class="text-deposit">
                                      {{ depositTitle }}
                                    </div>
                                    <div class="text-deposit">
                                      ฿{{ priceDeposit | currency }}.-
                                    </div>
                                  </div>
                                </v-btn>
                              </div>
                            </div>
                            <div
                              v-if="isShowBtnFullPayment"
                              class="btn-action-item"
                              :class="{ full: !isShowBtnDeposit }"
                            >
                              <div
                                :class="{
                                  'w-full': !isShowBtnDeposit,
                                }"
                              >
                                <Button
                                  @click="onClickFull"
                                  id="btn-full"
                                  block
                                  depressed
                                  large
                                  :disabled="
                                    disableAction ||
                                      isSoldOut ||
                                      isDisabled(
                                        selectSKU,
                                        'capacity',
                                      )
                                  "
                                  :height="
                                    !isShowBtnDeposit
                                      ? '60px'
                                      : '48px'
                                  "
                                  color="color-bnn"
                                >
                                  <template v-slot:content>
                                    <div class="full-payment-content">
                                      <div>
                                        ชำระเงินเต็มจำนวน
                                      </div>
                                      <div>
                                        ฿{{
                                          priceFullPayment | currency
                                        }}.-
                                      </div>
                                    </div>
                                  </template>
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="isShowBtnFullPaymentStaff"
                            class="w-full"
                          >
                            <div
                              id="btn-full"
                              @click="onClickFullStaff"
                              class="full-employee"
                            >
                              <span class="text-employee"
                                >ซื้อเต็มจำนวน หรือเลือกผ่อนชำระ
                                0%</span
                              >
                              <span class="text-payment"> คลิก</span>
                            </div>
                          </div>

                          <v-row
                            class="ship-date-row  w-full no-gutters"
                          >
                            <v-col cols="12">
                              <template v-if="!isSoldOut">
                                <div
                                  class="ship-date-summary-price-layout"
                                >
                                  <div class="ship p-0">
                                    <div>
                                      เริ่มจัดส่งตั้งแต่วันที่
                                      {{ shipDate }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </template>

                        <v-row
                          class="no-gutters w-full"
                          v-if="isShowConditionHtml"
                        >
                          <v-col cols="12">
                            <div class="condition">
                              <div>
                                <h4>Condition</h4>
                              </div>
                              <div
                                class="mt-3"
                                v-html="conditionHtml"
                              ></div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <!-- Button to cart -->
                <!-- 
                  <div class="cart-ship-wrapper" :class="cartClass">
                    <template v-if="isSoldOut">
                      <v-row>
                        <v-col>
                          <div>
                            <h3 class="text-bnn-red-2">สินค้าหมด:</h3>
                          </div>
                          <div>
                            Please visit our store or contact support to
                            check the availability.
                          </div>
                        </v-col>
                      </v-row>
    
                      <hr class="my-2 gray" />
                    </template>
                    <v-row class="button-to-cart-action">
                      <div class="action-contain-layout">
                        <template v-if="isSoldOut">
                          <div class="soldOut">
                            <Button
                              block
                              depressed
                              :disabled="disableAction"
                              color="color-bnn-opacity-90"
                              title="สินค้าหมด"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <div
                            v-if="isShowBtnDeposit"
                            class="deposit"
                            :class="{ 'w-full': !isShowBtnFullPayment }"
                          >
                            <template v-if="isShowBtnFullPayment">
                              <v-btn
                                @click="onClickDeposit"
                                id="btn-deposit"
                                block
                                outlined
                                class="btn-deposit"
                                height="48px"
                                :color="colorBnn['color-bnn']"
                              >
                                <div class="deposit-content">
                                  <div class="text-deposit">
                                    {{ depositTitle }}
                                  </div>
                                  <div class="text-deposit">
                                    ฿{{ priceDeposit | currency }}.-
                                  </div>
                                </div>
                              </v-btn>
                            </template>
                            <template v-else>
                              <Button
                                @click="onClickDeposit"
                                id="btn-deposit"
                                block
                                depressed
                                textLarge
                                fontNormal
                                :fontBold="false"
                                :disabled="disableAction"
                                height="60px"
                                color="color-bnn"
                                :title="depositTitle"
                              >
                                <div class="deposit-content">
                                  <div class="text-deposit">
                                    {{ depositTitle }}
                                  </div>
                                  <div>
                                    ฿{{ priceDeposit | currency }}.-
                                  </div>
                                </div>
                              </Button>
                            </template>
                          </div>
                          <div
                            v-if="isShowBtnFullPayment"
                            class="full"
                            :class="{ 'w-full': !isShowBtnDeposit }"
                          >
                            <Button
                              @click="onClickFull"
                              id="btn-full"
                              block
                              depressed
                              :disabled="disableAction"
                              height="48px"
                              color="color-bnn"
                            >
                              <template v-slot:content>
                                <div class="full-payment-content">
                                  <div>
                                    ชำระเงินเต็มจำนวน
                                  </div>
                                  <div>
                                    ฿{{ priceFullPayment | currency }}.-
                                  </div>
                                </div>
                              </template>
                            </Button>
                          </div>
                          <div
                            v-if="isShowBtnFullPaymentStaff"
                            id="btn-full"
                            @click="onClickFullStaff"
                            class="full-employee"
                          >
                            <span class="text-employee"
                              >ซื้อเต็มจำนวน หรือเลือกผ่อนชำระ 0%</span
                            >
                            <span class="text-payment"> คลิก</span>
                          </div>
                        </template>
                      </div>
                    </v-row> -->

                <!-- *** -->
                <!-- <v-row class="ship-date-row">
                    <v-col cols="12">
                      <template v-if="!isSoldOut">
                        <div class="ship-date-summary-price-layout">
                          <div class="ship">
                            <div>
                              <h4>
                                เริ่มจัดส่งตั้งแต่วันที่ {{ shipDate }}
                              </h4>
                            </div> -->
                <!-- <div class="ship-date">
                              <h4>{{ shipDate }}</h4>
                            </div> -->
                <!-- </div> -->
                <!-- <template v-if="isDeposit">
                            <div class="price"> -->
                <!-- Price Summary -->
                <!-- <div><h4>ยอดชำระส่วนที่เหลือ</h4></div>
                              <div>
                                <h4 class="price-summary-deposit">
                                  ฿{{ remainingAmount | currency }}.-
                                </h4>
                              </div> -->
                <!-- End Price Summary -->
                <!-- </div>
                          </template>
                          <template v-else>
                            <div class="price"> -->
                <!-- Price Summary -->
                <!-- <div><h4>ราคาทั้งหมด</h4></div>
                              <div>
                                <h4 class="price-summary">
                                  ฿{{ priceSummary | currency }}.-
                                </h4>
                              </div> -->
                <!-- End Price Summary -->
                <!-- </div>
                          </template> -->
                <!-- *** -->
                <!-- </div>
                      </template>
                    </v-col>
                  </v-row> -->
                <!-- *** -->
                <!-- End ShipDate -->
                <!-- End Button to cart -->

                <!-- Condition -->
                <!-- <v-row v-if="isShowConditionHtml">
                  <v-col cols="12">
                    <div class="condition">
                      <div>
                        <h3>Condition</h3>
                      </div>
                      <div class="mt-4" v-html="conditionHtml"></div>
                    </div>
                  </v-col>
                </v-row> -->
                <!-- End Condition -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Preview -->
          </v-container>

          <TabsProduct
            :tab-content="isTabContent"
            :tab-compare="isTabCompare"
          >
            <template v-if="isTabContent" v-slot:content>
              <div
                v-html="footerHtml"
                class="html-content mt-4"
              ></div>
            </template>
            <template v-if="isTabCompare" v-slot:compare>
              <div
                v-html="compareHtml"
                class="html-content mt-4"
              ></div>
            </template>
          </TabsProduct>

          <ModalTerm
            @agree="onAgreeModalTerm"
            @close="modalTerm = false"
            :show="modalTerm"
          />
          <div class="footer-margin"></div>
        </div>
      </template>
      <!-- NEW STUDIO7 -->
      <template v-else>
        <div class="full-page">
          <PDPProductFamilies
            :products="productFamilies"
            :isFromQRCode="isFromQRCode"
            :select="selectLevel1Code"
            @click="onClickLevel1"
          />

          <v-container>
            <div class="product-detail-layout">
              <PDPProductName
                :campaignName="modelName"
                :sku="sku"
                class="dp-block dp-md-none"
              />

              <div class="pdp-preview-swiper">
                <template v-if="thumbnailList.length > 0">
                  <PDPGallery :media="thumbnailList" />
                </template>
              </div>

              <div
                class="pdp-content"
                :class="{ 'select-by-sku': isFromQRCode }"
              >
                <div class="pdp-content-container">
                  <div>
                    <h3 class="hurry-up-label body-s">
                      <span class="badge-pre-order">
                        สั่งซื้อล่วงหน้า!
                      </span>
                      เริ่ม {{ startDate }}
                    </h3>
                    <div class="mt-4">
                      <CountDown
                        v-if="timeLeft > -1"
                        v-bind="{
                          size: 'md',
                          justify: 'flex-start',
                        }"
                        :timeLeft="timeLeft"
                        :isDefault="false"
                        :isColon="false"
                        isStudio7
                        isPDP
                        @end="reload"
                      />
                    </div>
                  </div>

                  <PDPProductName
                    :campaignName="modelName"
                    :sku="sku"
                    class="dp-none dp-md-block"
                  />

                  <PDPPricing
                    @openPaymentMethod="handleOpenPaymentMethod"
                  />

                  <div class="color-wrapper">
                    <div v-if="!isFromStockCard" class="mb-4">
                      <v-btn
                        text
                        class="btn-link promotion-privileges"
                        @click="handleOpenPromotionPrivileges"
                      >
                        <v-icon>
                          mdi-tag-outline
                        </v-icon>
                        ดูรายละเอียดโปรโมชั่นและสิทธิพิเศษเพิ่มเติม
                        <v-icon>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>

                    <div class="title-semibold mb-4">
                      <span>Color - </span>
                      <span>{{ selectColorName }}</span>
                    </div>
                    <div class="color-content">
                      <ButtonColor
                        v-for="(item, index) in p_level2"
                        :disabled="
                          (isFromQRCode &&
                            item.code !== selectLevel2Code) ||
                            item.disabled
                        "
                        :isShowCancelIcon="
                          (isFromQRCode &&
                            item.code !== selectLevel2Code) ||
                            item.disabled
                        "
                        :color="item.color_hex"
                        :height="40"
                        :width="40"
                        :select="item.code === selectLevel2Code"
                        :key="index"
                        @click="
                          isFromQRCode ? null : onClickLevel2(item)
                        "
                      >
                      </ButtonColor>
                    </div>
                  </div>

                  <div class="model-wrapper">
                    <div class="title-semibold mb-4">
                      <span>Model</span>
                      <!-- <span>{{ modelName }}</span> -->
                    </div>
                    <div class="model-container">
                      <ButtonModel
                        v-for="(item, index) in p_level3"
                        :select="item.code === selectLevel3Code"
                        :title="item.name"
                        :isShowPrice="false"
                        :price="item.start_price"
                        :key="index"
                        :disabled="
                          (isFromQRCode &&
                            item.code !== selectLevel3Code) ||
                            item.disabled
                        "
                        @click="
                          isFromQRCode ? null : onClickLevel3(item)
                        "
                      />
                    </div>
                  </div>

                  <div class="capacity-wrapper">
                    <div class="title-semibold mb-4">
                      <span>
                        Capacity
                        <!-- <span
                              v-if="!selectColor"
                              class="text-red pb-2"
                            >
                              * กรุณาเลือก{{ titleTextCapacity }}
                            </span> -->
                      </span>
                      <!-- <span class="font-bold">
                            {{ selectCapacityName }}
                          </span> -->
                    </div>
                    <div class="capacity-content">
                      <template v-if="selectLevel3">
                        <ButtonSpec
                          v-for="(item, index) in p_level4"
                          @click="
                            isFromQRCode ? null : onClickLevel4(item)
                          "
                          :disabled="
                            (isFromQRCode && item.sku !== sku) ||
                              item.disabled
                          "
                          :title="item.size"
                          :select="item.sku === sku"
                          :key="index"
                        />
                      </template>
                      <template v-else>
                        <v-skeleton-loader
                          v-for="(item, index) in [0, 1, 2]"
                          type="button"
                          :key="index"
                        ></v-skeleton-loader>
                      </template>
                    </div>
                  </div>

                  <AppleCare
                    v-if="appleCare && appleCare.active"
                    @change="checkSummary"
                    is-card
                  />

                  <PDPQuantity />

                  <div>
                    <div class="title-medium mb-4">
                      Get it fast
                    </div>
                    <PDPShipping />
                  </div>

                  <PDPAccordion :items="pdpInfoAccordion" />
                </div>

                <!-- <div v-if="hasAlSoBought" class="title-also-bought">
                      Also Bought
                    </div> -->

                <!-- Apple Care -->
                <!-- <AppleCare
                      v-if="appleCare && appleCare.active"
                      @change="checkSummary"
                      is-card
                    /> -->
                <!-- End Apple Care -->

                <!-- Accessory Bundles -->
                <!-- <div v-if="accessoryBundles.length > 0" class="py-4">
                      <AccessoryBundle
                        v-for="(accessory, index) in accessoryBundles"
                        :key="index"
                        :accessoryBundle="accessory"
                        @change="checkSummary"
                        is-button
                      />
                    </div> -->
                <!-- End  Accessory Bundles -->

                <!-- Bundle -->
                <!-- <v-row v-if="isShowSwiperBundle && !isFromStockCard">
                      <v-col cols="12">
                        <span class="text-label"
                          >Save With Bundle :
                        </span>
                        <span class="font-bold">
                          {{ bundleList }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <template v-if="bundlesItemsNoFreebie">
                          <BundleSwiper
                            @click="onClickSelectBundle"
                            :data="bundlesItemsNoFreebie"
                            key="bundle"
                          />
                        </template>
                      </v-col>
                    </v-row> -->
                <!-- End Bundle -->

                <!-- ShipDate -->
                <div ref="cartShip"></div>
                <!-- :class="cartClass" -->
                <!-- <div
                      class="cart-ship-wrapper mt-0 mt-md-4 cart-fixed"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="4"> </v-col>
                          <v-col cols="4">
                            <div class="bg-light-500">
                              <div class="headline-l">
                                ฿{{ priceFullPayment | currency }}
                              </div>
                              <template>
                                <div class="title-medium">
                                  หรือ ฿{price} / เดือน ผ่อน {percent}%
                                  นานสูงสุด {period} เดือน
                                </div>
                                <a>
                                  ตัวเลือกแผนการผ่อนชำระ 0%
                                  <v-icon>
                                    mdi-chevron-right
                                  </v-icon>
                                </a>
                              </template>
                            </div>
                            <v-row
                              class="no-gutters w-full"
                              v-if="isShowConditionHtml"
                            >
                              <v-col cols="12">
                                <div class="condition">
                                  <div>
                                    <h4>Condition</h4>
                                  </div>
                                  <div
                                    class="mt-3"
                                    v-html="conditionHtml"
                                  ></div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="4">
                            <div class="wrap-btn-action">
                              <div class="wrap-btn-action-case">
                                <div
                                  v-if="isShowBtnDeposit"
                                  class="btn-action-item"
                                  :class="{
                                    full: !isShowBtnFullPayment,
                                  }"
                                >
                                  <div class="deposit">
                                    <v-btn
                                      @click="onClickDeposit"
                                      id="btn-deposit"
                                      outline
                                      depressed
                                      large
                                      :outlined="isShowBtnFullPayment"
                                      :height="
                                        !isShowBtnFullPayment
                                          ? '60px'
                                          : '48px'
                                      "
                                      :color="
                                        !isSoldOut
                                          ? colorBnn['color-bnn']
                                          : 'color-bnn-opacity-90'
                                      "
                                      :disabled="
                                        isSoldOut ||
                                          isDisabled(
                                            selectSKU,
                                            'capacity',
                                          )
                                      "
                                    >
                                      <div class="deposit-content">
                                        <div class="text-deposit">
                                          {{ depositTitle }}
                                        </div>
                                        <div class="text-deposit">
                                          ฿{{ priceDeposit | currency }}.-
                                        </div>
                                      </div>
                                    </v-btn>
                                  </div>
                                </div>
                                <div
                                  v-if="isShowBtnFullPayment"
                                  class="btn-action-item"
                                  :class="{ full: !isShowBtnDeposit }"
                                >
                                  <div
                                    :class="{
                                      'w-full': !isShowBtnDeposit,
                                    }"
                                  >
                                    <Button
                                      @click="onClickFull"
                                      id="btn-full"
                                      depressed
                                      large
                                      :disabled="
                                        disableAction ||
                                          isSoldOut ||
                                          isDisabled(
                                            selectSKU,
                                            'capacity',
                                          )
                                      "
                                      :height="
                                        !isShowBtnDeposit
                                          ? '60px'
                                          : '48px'
                                      "
                                      color="color-bnn"
                                    >
                                      <template v-slot:content>
                                        <div class="full-payment-content">
                                          <div>
                                            ชำระเงินเต็มจำนวน
                                          </div>
                                          <div>
                                            ฿{{
                                              priceFullPayment | currency
                                            }}.-
                                          </div>
                                        </div>
                                      </template>
                                    </Button>
                                  </div>
                                </div>
                              </div>
    
                              <v-btn
                                v-if="isSoldOut"
                                depressed
                                large
                                disabled
                              >
                                สินค้าหมด
                              </v-btn>
    
                              <div
                                v-if="isShowBtnFullPaymentStaff"
                                class="w-full"
                              >
                                <div
                                  id="btn-full"
                                  @click="onClickFullStaff"
                                  class="full-employee"
                                >
                                  <span class="text-employee"
                                    >ซื้อเต็มจำนวน หรือเลือกผ่อนชำระ
                                    0%</span
                                  >
                                  <span class="text-payment"> คลิก</span>
                                </div>
                              </div>
    
                              <v-row
                                class="ship-date-row  w-full no-gutters"
                              >
                                <v-col cols="12">
                                  <template v-if="!isSoldOut">
                                    <div
                                      class="ship-date-summary-price-layout"
                                    >
                                      <div class="ship p-0">
                                        <div>
                                          เริ่มจัดส่งตั้งแต่วันที่
                                          {{ shipDate }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </v-col>
                              </v-row>
                            </div>
                            <v-col
                              cols="12"
                              class="my-2"
                              v-if="isSoldOut"
                            >
                              <div>
                                <h3 class="text-bnn-red-2">สินค้าหมด</h3>
                              </div>
                              <div>
                                Please visit our store or contact support
                                to check the availability.
                              </div>
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div> -->
              </div>
            </div>
            <!-- End Content -->

            <PDPRiverContent
              :title="`${modelName}`"
              :isHasCompare="isTabCompare"
              :isHasLearnMore="isTabContent"
              @learnMore="handleOpenLearnMore"
              @compare="handleOpenCompare"
            />

            <PDPAccordion
              :items="pdpOverviewAccordion"
              isOverview
              flat
              expandedDefault
            />

            <PDPProductAlsoBought
              v-if="isShowSwiperBundle && !isFromStockCard"
              title="ซื้อรวมกันบ่อย"
              isBundle
              keyProduct="bundle"
              :items="bundlesItemsNoFreebie"
              @click="checkSummary"
            />

            <!-- accessoryBundles -->
            <!-- <PDPProductAlsoBought
                  v-if="accessoryBundles.length > 0"
                  title="ซื้อรวมกันบ่อย"
                  isBundle
                  :items="accessoryBundles"
                  @click="checkSummary"
                /> -->

            <PDPModalLearnMore
              v-if="isTabContent"
              ref="PDPModalLearnMore"
              :htmlContent="footerHtml"
            />

            <PDPModalCompare
              v-if="isTabCompare"
              ref="PDPModalCompare"
              :htmlContent="compareHtml"
            />

            <PDPModalPaymentMethod ref="PDPModalPaymentMethod" />

            <PDPModalPromotionPrivileges
              ref="PDPModalPromotionPrivileges"
            />
          </v-container>

          <PDPStickyCTA
            ref="PDPStickyCTA"
            data-pptr="PDPStickyCTA"
            @openPaymentMethod="handleOpenPaymentMethod"
          >
            <template v-slot:cta>
              <div class="wrap-btn-action">
                {{ getHeightPDPStickyCTA }}
                <v-btn v-if="isSoldOut" depressed large disabled>
                  สินค้าหมด
                </v-btn>
                <div v-else class="wrap-btn-action-case">
                  <div
                    v-if="isShowBtnDeposit"
                    class="btn-action-item"
                    :class="{
                      full: !isShowBtnFullPayment,
                    }"
                  >
                    <div class="deposit">
                      <v-btn
                        @click="onClickDeposit"
                        id="btn-deposit"
                        depressed
                        block
                        :dark="!isBnn && !isShowBtnFullPayment"
                        :outlined="isShowBtnFullPayment"
                        :color="
                          !isSoldOut
                            ? colorBnn['color-bnn']
                            : 'color-bnn-opacity-90'
                        "
                        :disabled="
                          isSoldOut ||
                            isDisabled(selectSKU, 'capacity')
                        "
                      >
                        {{ depositTitle }}
                      </v-btn>
                    </div>
                  </div>
                  <div
                    v-if="isShowBtnFullPayment"
                    class="btn-action-item"
                    :class="{ full: !isShowBtnDeposit }"
                  >
                    <div
                      :class="{
                        'w-full': !isShowBtnDeposit,
                      }"
                    >
                      <v-btn
                        @click="onClickFull"
                        id="btn-full"
                        depressed
                        block
                        :disabled="
                          disableAction ||
                            isSoldOut ||
                            isDisabled(selectSKU, 'capacity') ||
                            hasWarrantyInCartOnline
                        "
                        :color="
                          !isSoldOut
                            ? colorBnn['color-bnn']
                            : 'color-bnn-opacity-90'
                        "
                      >
                        <div class="full-payment-content text-white">
                          <div>
                            จองพร้อมจ่ายเต็ม
                          </div>
                        </div>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="isShowBtnFullPaymentStaff" class="w-full">
                  <div
                    id="btn-full"
                    @click="onClickFullStaff"
                    class="full-employee"
                  >
                    <span class="text-employee"
                      >ซื้อเต็มจำนวน หรือเลือกผ่อนชำระ 0%</span
                    >
                    <span class="text-payment"> คลิก</span>
                  </div>
                </div> -->
              </div>
            </template>
          </PDPStickyCTA>

          <ModalTerm
            @agree="onAgreeModalTerm"
            @close="modalTerm = false"
            :show="modalTerm"
          />
        </div>
      </template>
    </template>
    <template v-else>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="image"
            ></v-skeleton-loader>
          </v-col>
          <v-col md="6" sm="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="article"
            ></v-skeleton-loader>

            <v-skeleton-loader
              v-bind="attrs"
              type="list-item"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item"
            ></v-skeleton-loader>

            <v-skeleton-loader
              v-bind="attrs"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Load from './Load'
import loadCampaign from '../utils/loadcampaign'
import ButtonModel from '@/components/bnn/ButtonModel'
import ButtonColor from '@/components/bnn/ButtonColor'
import ButtonSpec from '@/components/bnn/ButtonSpec'
import Button from '@/components/bnn/Button'
// import Banner from '@/components/bnn/Banner'
import BundleSwiper from '@/components/bnn/BundleSwiper'
import PreviewImageSwiper from '@/components/bnn/PreviewImageSwiper'
import ModalTerm from '@/components/bnn/ModalTerm'
import CountDown from '@/components/bnn/CountDown'
import AppleCare from '@/components/bnn/AppleCare'
// import DeliveryInfo from '@/components/bnn/DeliveryInfo'
import LabelProduct from '@/components/bnn/LabelProduct'
import TabsProduct from '@/components/bnn/TabsProduct'
import AccessoryBundle from '@/components/bnn/AccessoryBundle'
import branchTypes from '@/config/branchTypes'
// import numeral from 'numeral'
import colorBnn from '@/config/color'
import moment from 'moment'
import routePage from '@/config/routePage'
import config from '@/config/index'
import cartSummary from '@/mixins/cartSummary'
import imageCfUrl from '@/config/imageCfUrl'
import { STOCK_CARD_TYPE } from '@/config/stockCard'
import isBnn from '@/config/isBnn'

const { showFreeBie } = config

import { INSTALLMENT } from '@/config/index'

export default {
  mixins: [cartSummary],

  computed: {
    ...mapState({
      modelLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      campaign: state => state.campaign.current,
      campaignLoading: state => state.campaign.isLoading,

      model: state => state.model.selectModel,
      selectColor: state => state.model.selectColor,
      //   selectSKU: state => state.model.selectSKU,

      //   New Studio7
      selectLevel1: state => state.model.selectGroup,
      selectLevel2: state => state.model.selectColor,
      selectLevel3: state => state.model.selectModel,
      selectSKU: state => state.model.selectSKU,

      productData: state => state.model.products,
      paymentTypeData: state => state.paymentType.data,
      branchType: state => state.branchStore.branchType,
      cartSummaryData: state => state.cart.cartSummaryData,
      isCartLoading: state => state.cart.isLoading,
      additionalProduct: state => state.model.additionalProduct,
    }),

    ...mapGetters({
      cartItems: 'cart/cartItems' ?? [],
      modelItems: 'model/getModels',
      colorItems: 'model/getColors',
      storageItems: 'model/getStorages',
      priceSummary: 'cart/priceSummary',
      bundlesItems: 'model/getBundles',
      soldOut: 'model/getSoldOut',
      appleCare: 'model/getAppleCare',
      getFreebie: 'model/getFreebie',
      accessoryBundles: 'model/getAccessoryBundles',

      p_level1: 'model/getProductLevel1',
      p_level2: 'model/getProductLevel2',
      p_level3: 'model/getProductLevel3',
      p_level4: 'model/getProductLevel4',

      productFamilies: 'model/getProductFamilies',
    }),

    selectLevel1Code() {
      return this.selectLevel1?.code ?? ''
    },

    selectLevel2Code() {
      return this.selectLevel2?.code ?? ''
    },

    selectLevel3Code() {
      return this.selectLevel3?.code ?? ''
    },

    cartClass() {
      // get position div class cart-ship-wrapper

      return {
        'cart-fixed': this.scrollY > this.positionCartShip,
      }
    },

    isLoading() {
      return this.campaignLoading || this.modelLoading
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    isDeliveryHome() {
      return this.campaign?.data?.is_delivery_home
    },

    isShowBtnDeposit() {
      const { t } = this.$route.query
      if (this.campaign?.data?.btn_deposit && !t) {
        return (
          this.paymentTypeData?.filter(i => i.type === 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    isShowBtnFullPayment() {
      if (this.campaign?.data?.btn_full_customer) {
        return (
          this.paymentTypeData?.filter(i => i.type !== 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    isShowBtnFullPaymentStaff() {
      if (this.campaign?.data?.btn_full_staff) {
        return (
          this.paymentTypeData?.filter(i => i.type !== 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    bundlesItemsNoFreebie() {
      return this.bundlesItems?.filter(
        i => i?.type !== 'free' && i?.type !== 'free_handraiser',
      )
    },

    isShowSwiperBundle() {
      return this.bundlesItemsNoFreebie?.length > 0
    },

    isFromStockCard() {
      return !!this.$route.query.t || !!this.$route.query.code
    },

    freebieItems() {
      return (
        this.getFreebie?.map(i => {
          return {
            id: i.id,
            sku: i.sku,
            name: i.name,
            image: i.image_url,
            limit: i.limit,
            price: i.price_srp,
          }
        }) ?? []
      )
    },

    isShowLabelFreebie() {
      return (
        this.freebieItems?.length > 0 &&
        this.freebieItems?.filter(i =>
          this.cartItems.find(c => c.sku === i.sku),
        )
      )
    },

    modelName() {
      return this.model?.name
    },

    campaignId() {
      return this.campaign?.data?.id ?? ''
    },

    campaignName() {
      return this.campaign?.data?.name
    },

    stickyBanner() {
      return this.campaign?.data?.sticky_banner
    },

    stickyLink() {
      return this.campaign?.data?.sticky_link
    },

    campaignStatus() {
      return this.campaign?.data?.status
    },

    conditionHtml() {
      return this.selectSKU?.condition_html
    },

    rotateHtml() {
      return (
        this.selectSKU?.html_360 || this.selectSKU?.condition_html
      )
    },

    isShowConditionHtml() {
      if (!this.selectSKU?.condition_html) {
        return false
      }
      return true
    },

    previewHtml() {
      return this.selectSKU?.preview_html
      //  ??
      // this.campaign?.data?.preview_html
    },

    footerHtml() {
      return this.selectSKU?.footer_html
      // ??
      // this.campaign?.data?.footer_html
    },

    compareHtml() {
      return this.selectSKU?.compare_html
    },

    shipDate() {
      return (
        this.selectSKU.shipping_date ||
        this.campaign?.data?.shipdate ||
        'ไม่พบข้อมูล'
      )
    },

    selectColorName() {
      return this.selectColor?.color ?? ''
    },

    selectCapacityName() {
      return this.selectSKU?.size ?? ''
    },

    forceBuyBundle() {
      return this.selectSKU?.force_bundle === 1
    },

    slug() {
      return this.campaign?.data?.slug
    },

    isSoldOut() {
      if (this.soldOut) {
        return this.soldOut
      } else {
        return !this.selectSKU?.active
      }
    },

    sku() {
      return this.selectSKU?.sku
    },

    remainingAmount() {
      return this.priceSummary - this.selectSKU?.price_deposit
    },

    priceDeposit() {
      return this.cartSummaryData?.product_price_deposit_summary ?? 0
    },

    priceFullPayment() {
      // Notes: Previous code is cartSummaryData.final_price
      return this.cartSummaryData?.final_price_original ?? 0
    },

    depositTitle() {
      // TODO: remove
      // if (this.selectSKU?.price_deposit) {
      //   return `มัดจำค่าเครื่อง ฿${numeral(
      //     this.selectSKU?.price_deposit ?? 0,
      //   ).format('0,0')}.-`
      // }
      //   `มัดจำค่าเครื่อง`
      // สั่งซื้อล่วงหน้า แบบมัดจำ
      return 'จองพร้อมมัดจำ'
    },

    thumbnailList() {
      let imageList

      if (!imageList) {
        imageList = this.selectSKU?.images?.map((items, index) => {
          let img = imageCfUrl + 'width=1000/' + items
          return { id: index + 1, imageUrl: img }
        })
      }

      if (!imageList) {
        imageList = this.selectColor?.data?.[0]?.images?.map(
          (items, index) => {
            let img = imageCfUrl + 'width=1000/' + items
            return { id: index + 1, imageUrl: img }
          },
        )
      }

      if (!imageList) {
        imageList = this.model?.model?.map((items, index) => {
          let img =
            imageCfUrl + 'width=1000/' + items.data[0].image_url
          return { id: index + 1, imageUrl: img }
        })
      }

      return imageList ?? []
    },

    disableAction() {
      return !this.selectSKU
    },

    endDateTime() {
      return this.campaign?.data?.ended_at_timestamp * 1000
    },

    endTime() {
      return moment(
        this.campaign?.data?.ended_at_timestamp * 1000,
      ).format('HH:mm')
    },

    timeLeft() {
      if (this.campaign?.data?.status === 'active') {
        return (
          (this.campaign?.data?.ended_at_timestamp -
            this.campaign?.data?.current_datetime_timestamp) *
            1000 ?? -1
        )
      }
      return -1
    },

    bundleList() {
      if (this.selectSKU) {
        const bundleItem = this?.cartItems
          ?.map(item => {
            if (
              item?.type === 'bundle' &&
              item?.sku !== this.appleCare?.sku
            ) {
              return ` ${item?.name} `
            }
          })
          ?.filter(i => i)

        return bundleItem.toString()
      }
      return ''
    },

    titleTextCapacity() {
      return localStorage.getItem('slug')?.indexOf(`watch`) > -1
        ? 'สาย'
        : 'สี'
    },

    hasAdditionalProduct() {
      return this.additionalProduct?.length > 0
    },

    hasAlSoBought() {
      return (
        this.appleCare &&
        this.appleCare?.active &&
        this.accessoryBundles?.length > 0
      )
    },

    isFromQRCode() {
      const { sku, code } = this.$route.query
      if (sku && code) {
        return true
      }
      return false
    },

    isTabContent() {
      return this.footerHtml ? true : false
    },

    isTabCompare() {
      return this.compareHtml ? true : false
    },

    startDate() {
      return moment(this.campaign?.data?.started_at).format(
        'DD MMMM YYYY',
      )
    },

    pdpInfoAccordion() {
      return [
        {
          title: 'การผ่อนชำระเงิน',
          desc: `ผ่อนชำระสูงสุด ${INSTALLMENT.MAX} เดือน ผ่านบัตรเครดิต พร้อมรับเครดิตเงินคืน`,
        },
        {
          title: 'การรับประกัน',
          desc: 'การรับประกันหลังการขายที่ศูนย์ iCare สาขาทั่วประเทศ',
        },
        // {
        //   title: 'Apple service',
        //   desc: 'ลงทะเบียนรับ Apple Service ฟรีนาน 3 เดือน',
        // },
        // {
        //   title: 'รายละเอียดการจัดส่ง',
        //   desc:
        //     'จัดส่งฟรีทั่วประเทศ - เลือกรับสินค้าได้ทุกสาขาใน 1 ชั่วโมง<br/>(ตั้งแต่ 10:00 - 16:00 น.)',
        // },
      ]
    },

    pdpOverviewAccordion() {
      return [
        {
          title: 'ภาพรวมสินค้า',
          desc: this.previewHtml,
        },
      ]
    },

    hasWarrantyInCartOnline() {
      return (
        this.cartItems.some(item => item?.type === 'warranty') &&
        !this.$route.query.code
      )
    },
  },

  components: {
    Load,
    ButtonModel,
    ButtonColor,
    ButtonSpec,
    Button,
    BundleSwiper,
    PreviewImageSwiper,
    ModalTerm,
    // Banner,
    CountDown,
    AppleCare,
    // DeliveryInfo,
    LabelProduct,
    TabsProduct,
    AccessoryBundle,

    // studio7 Style
    PDPProductName: () =>
      import('@/components/studio7/PDPProductName'),
    PDPPricing: () => import('@/components/studio7/PDPPricing'),
    PDPStickyCTA: () => import('@/components/studio7/PDPStickyCTA'),
    PDPGallery: () => import('@/components/studio7/PDPGallery'),
    PDPRiverContent: () =>
      import('@/components/studio7/PDPRiverContent'),

    PDPQuantity: () => import('@/components/studio7/PDPQuantity'),
    PDPAccordion: () => import('@/components/studio7/PDPAccordion'),
    PDPProductAlsoBought: () =>
      import('@/components/studio7/PDPProductAlsoBought'),
    PDPShipping: () => import('@/components/studio7/PDPShipping'),

    PDPModalLearnMore: () =>
      import('@/components/studio7/PDPModalLearnMore'),
    PDPModalCompare: () =>
      import('@/components/studio7/PDPModalCompare'),
    PDPModalPaymentMethod: () =>
      import('@/components/studio7/PDPModalPaymentMethod'),
    PDPModalPromotionPrivileges: () =>
      import('@/components/studio7/PDPModalPromotionPrivileges'),

    PDPProductFamilies: () =>
      import('@/components/studio7/PDPProductFamilies'),
  },

  data() {
    return {
      branchTypes,
      keyPreview: 0,
      colorBnn,
      isBnn,
      modalTerm: false,
      showFreeBie,
      scrollY,
      positionCartShip: 0,
      attrs: {
        width: '100%',
      },
    }
  },

  async mounted() {
    await this.refetch()
    if (this.isShowBtnDeposit && !this.isShowBtnFullPayment) {
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_DEPOSIT,
      )
      this.$store.commit('cart/delivery', 'store')
    }

    window.addEventListener('scroll', this.handleScroll)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },

  methods: {
    reload() {
      window.location.reload()
    },

    async refetch() {
      this.$store.commit('cart/resetCouponCode')
      const active = await loadCampaign(this.campaign)

      if (this.campaign?.data?.redirect_url) {
        window.location.href = this.campaign?.data?.redirect_url
      }
      if (active) {
        document.body.style.overflowY = ''
        document.querySelector('html').style.overflowY = ''

        await Promise.all([
          this.$store.dispatch('model/getModel', {
            slug: this.slug,
            reload: true,
          }),
          this.$store.dispatch('model/getStocks', {
            slug: this.slug,
          }),
        ])

        this.$store.dispatch('model/updateStatusSKU')

        // Select follow by query param sku
        const { sku } = this.$route.query
        if (sku) {
          this.findSku(this.modelItems, sku)
        } else {
          this.$store.dispatch('model/autoSelect')

          this.$store.dispatch('model/reset')
          this.$store.dispatch('bundle/reset')
          this.autoSelectForceBundle()
          this.autoSelectFreeBundle()
          this.checkSummary()
        }

        // Set route product latest visit
        localStorage.setItem('productLatestVisit', this.slug)

        // Note: Hide config select sku
        //  else {
        //   if (isBnn) {
        //     let BNN = '195949036255'
        //     this.findSku(this.modelItems, BNN)
        //   } else {
        //     let STU = '195949036255'
        //     this.findSku(this.modelItems, STU)
        //   }
        // }

        // auto select first visit page
        // if (!this.isFromQRCode && !this.selectSKU) {
        //   this.autoSelectSKU('select_model', this.modelItems?.[0])
        // }

        await this.$store.dispatch(
          'paymentType/getPaymentType',
          this.campaignId,
        )

        this.$forceUpdate()
      }
    },

    async findSku(data, sku) {
      this.isLoading = true

      this.$store.dispatch('model/autoSelectBySKU', sku)

      this.$store.dispatch('model/updateStatusSKU')
      this.$store.dispatch('bundle/reset')
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()

      this.isLoading = false

      // BNN

      //   this.isLoading = true

      //   for (let i in data) {
      //     for (let j in data[i]?.model) {
      //       for (let k in data[i]?.model[j]?.data) {
      //         if (data[i]?.model[j]?.data[k]?.sku === sku) {
      //           this.$store.dispatch('model/selectModel', data[i])
      //           this.$store.dispatch(
      //             'model/selectColor',
      //             data[i]?.model[j],
      //           )
      //           this.$store.dispatch(
      //             'model/selectSKU',
      //             data[i]?.model[j]?.data[k],
      //           )
      //           this.$store.dispatch('bundle/reset')
      //           this.autoSelectForceBundle()
      //           this.autoSelectFreeBundle()
      //           this.checkSummary()
      //         }
      //       }
      //     }
      //   }

      //   this.isLoading = false

      //   if (!this.selectSKU) {
      //     this.autoSelectSKU('select_model', this.modelItems?.[0])
      //   }
    },

    async onClickLevel1(item) {
      this.keyPreview++
      await this.$store.dispatch('model/selectLevel1', item)
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()
      this.$forceUpdate()
    },

    async onClickLevel2(item) {
      this.keyPreview++
      await this.$store.dispatch('model/selectLevel2', item)
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()
      this.$forceUpdate()
    },

    async onClickLevel3(item) {
      this.keyPreview++
      await this.$store.dispatch('model/selectLevel3', item)
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()
      this.$forceUpdate()
    },

    async onClickLevel4(item) {
      this.keyPreview++
      await this.$store.dispatch('model/selectLevel4', item)
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()
      this.$forceUpdate()
    },

    onClickModel(item) {
      this.keyPreview++
      this.$store.dispatch('model/selectModel', item)
      this.autoSelectSKU('select_model', item)
    },

    onClickColor(item) {
      this.keyPreview++
      this.$store.dispatch('model/selectColor', item)
      this.autoSelectSKU('select_color', item)
    },

    onClickCapacity(item) {
      this.keyPreview++
      this.$store.dispatch('model/selectSKU', item)
      this.$store.dispatch('bundle/reset')
      this.autoSelectForceBundle()
      this.autoSelectFreeBundle()
      this.checkSummary()
      this.$forceUpdate()
    },

    onClickSelectBundle(item) {
      if (!this.forceBuyBundle) {
        this.$store.dispatch('bundle/toggle', item)
      }
      this.$forceUpdate()
      this.checkSummary()
    },

    onClickFull() {
      this.modalTerm = true
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_FULL_PAYMENT,
        //  branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      )
      if (this.isDeliveryHome) {
        this.$store.commit('cart/delivery', 'delivery')
      } else {
        this.$store.commit('cart/delivery', 'store')
      }

      const isStockCardOffline =
        this.$route.query?.t &&
        this.$route.query?.t !== STOCK_CARD_TYPE.QR_ONLINE

      let id = isStockCardOffline
        ? this.paymentTypeData?.filter(
            i => i.type === 'pay-at-store',
          )?.[0]?.id
        : this.paymentTypeData?.filter(i => i.type !== 'deposit')?.[0]
            ?.id

      this.$store.commit('paymentType/setPaymentId', id)
      this.checkSummary()
    },

    onClickFullStaff() {
      this.modalTerm = true
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_FULL_PAYMENT,
        //  branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      )
      this.$store.commit('cart/delivery', 'store')
      this.$store.commit(
        'paymentType/setPaymentId',
        this.paymentTypeData?.filter(i => i.type !== 'deposit')?.[0]
          ?.id,
      )
      this.checkSummary()
    },

    onClickDeposit() {
      this.modalTerm = true
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_DEPOSIT,
        //  branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      )
      this.$store.commit('cart/delivery', 'store')
      this.$store.commit(
        'paymentType/setPaymentId',
        this.paymentTypeData?.filter(i => i.type === 'deposit')?.[0]
          ?.id,
      )
      this.checkSummary()
      // this.$router.push({
      //   name: 'CustomerAlsoBought',
      //   params: { slug: this.slug },
      // })
    },

    onAgreeModalTerm() {
      this.modalTerm = false
      if (this.hasAdditionalProduct) {
        this.$router
          .push({
            name:
              routePage?.[process.env.VUE_APP_CAMPAIGN_TYPE]?.[
                this.branchType
              ]?.[this.$route.name]?.nextPage,
            params: { slug: this.slug },
          })
          .catch(() => true)
      } else {
        this.$router
          .push({
            name: 'Cart',
            params: {
              slug: this.slug,
            },
          })
          .catch(() => true)
      }
    },

    autoSelectForceBundle() {
      if (this.forceBuyBundle) {
        this.bundlesItems.forEach(item => {
          if (item.active) this.$store.dispatch('bundle/toggle', item)
        })

        // if (this.appleCare.active) {
        //   this.$store.dispatch('bundle/toggle', this.appleCare)
        // }
      }
    },

    autoSelectFreeBundle() {
      this.bundlesItems?.forEach(item => {
        if (item.type === 'free' && item.active)
          this.$store.dispatch('bundle/toggle', item)
      })
      this.appleCare?.type === 'free' && this.appleCare?.active
        ? this.$store.dispatch('bundle/toggle', this.appleCare)
        : null
    },

    autoSelectSKU(
      type = 'select_model',
      selectItem = this.modelItems?.[0],
    ) {
      if (type === 'select_model') {
        let indexColor = 0
        let indexSKU = 0

        if (this.isDisabled(selectItem, 'model')) {
          for (const i in this?.modelItems) {
            if (!this.isDisabled(this?.modelItems?.[i], 'model')) {
              selectItem = this?.modelItems?.[i]
              this.$store.dispatch('model/selectModel', selectItem)
              break
            }
          }
        }

        for (const i in selectItem?.model) {
          if (!this.isDisabled(selectItem?.model?.[i], 'color')) {
            // ? Note: Ignore disabled
            // if (selectItem?.model?.[i]) {
            indexColor = i
            break
          }
        }
        for (const i in selectItem?.model?.[indexColor]?.data) {
          if (
            !this.isDisabled(
              selectItem?.model?.[indexColor]?.data?.[i],
              'capacity',
            )
          ) {
            // ? Note: Ignore disabled
            //   if (selectItem?.model?.[indexColor]?.data?.[i]) {
            indexSKU = i
            break
          }
        }

        this.$store.dispatch(
          'model/selectColor',
          selectItem?.model?.[indexColor],
        )
        this.onClickCapacity(
          selectItem?.model?.[indexColor]?.data?.[indexSKU],
        )
      } else if (type === 'select_color') {
        let indexSKU = 0
        for (const i in selectItem?.data) {
          if (!this.isDisabled(selectItem?.data?.[i], 'capacity')) {
            // ? Note: Ignore disabled
            //   if (selectItem?.data?.[i]) {
            indexSKU = i
            break
          }
        }
        this.onClickCapacity(selectItem?.data?.[indexSKU])
      }
    },

    isDisabled(item, type = 'model') {
      // TODO: check nesting stock
      // old code return false && item
      let active = false

      const { sku } = this.$route.query

      if (this.isFromQRCode) {
        if (item === this.selectSKU) {
          return false
        } else if (type === 'model') {
          for (const models of item?.model) {
            for (const model of models?.data) {
              if (model?.active && model?.sku === sku) {
                active = true
                break
              }
            }
          }
          return !active
        } else if (type === 'color') {
          item?.data?.forEach(i => {
            if (i?.active && i?.sku === sku) {
              active = true
            }
          })
          return !active
        }
        return true
      } else {
        if (type === 'model') {
          for (const models of item?.model) {
            for (const sku of models?.data) {
              if (sku?.active) {
                active = true
                break
              }
            }
          }
          return !active
        } else if (type === 'color') {
          item?.data?.forEach(i => {
            if (i?.active) {
              active = true
            }
          })
          return !active
        } else if (type === 'capacity') {
          return !item?.active
        }
      }
    },

    handleScroll() {
      const checkoutActionHeight = document.querySelector(
        '.cart-ship-wrapper',
      )?.offsetHeight
      this.scrollY =
        window.scrollY + window.innerHeight - checkoutActionHeight
      // this.scrollY = window.scrollY
      this.positionCartShip = this.$refs.cartShip?.offsetTop
    },

    handleOpenLearnMore() {
      this.$refs.PDPModalLearnMore.openModal()
    },

    handleOpenCompare() {
      this.$refs.PDPModalCompare.openModal()
    },

    async handleOpenPaymentMethod() {
      await this.$store.dispatch(
        'staticPage/getStaticPage',
        'installment-plan-modal',
      )

      this.$refs.PDPModalPaymentMethod.openModal()
    },

    async handleOpenPromotionPrivileges() {
      await this.$store.dispatch(
        'staticPage/getStaticPage',
        'promotion-and-privileges-modal',
      )

      this.$refs.PDPModalPromotionPrivileges.openModal()
    },
  },
}
</script>

<style lang="stylus" scoped>

// ::v-deep img {
//   width: 100%;
// }

.product-detail {
  margin-top: 150px;
  @media small {
    margin-top: 200px;
  }
}

.color-content {
  display: grid;
  gap: 16px 4px;
  grid-template-columns: repeat(8, 1fr);

  @media small {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap !important;
   background: white;
   padding: 0
   gap 24px
  }
}

.capacity-content {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (max-width: 768px) {
     grid-template-columns: repeat(1, 1fr);
  }
}

.condition {
    display: block;
    overflow: hidden;
    border-radius: 8px
    background: $color-bnn-gray-light-2
    padding: 0.75rem 1rem
    @media small {
      overflow: scroll;
      max-height: 120px
    }
}

.text-label {
  color: $color-bnn-gray-medium-2;
  font-weight: bold
}

.price-summary {
  color:  $color-text-price
  color:  $color-bnn
  font-weight: bold
  font-size: 1rem
  text-align: right
  // line-height: 2.25rem
}

.price-summary-deposit {
  color:  $color-bnn
  font-weight: bold
  font-size: 1rem
  text-align: right
  // line-height: 2.25rem
}

.text-bnn-red-2 {
  color: $color-bnn-red-2
}

.text-ship-date {
    color: $color-bnn-blue-2
    font-weight: bold
}

.gallery {
    display: flex
    justify-content: center
    flex-direction: column
    justify-content: flex-start
    padding-right: 5rem
    margin-top: -2.2rem
    @media screen and (max-width: 960px) {
      padding: 0
      display: flex
      justify-content: center
      width: 100%
    }
}

.harry-up
    color: $color-text-harry-up

.preview-html
  overflow: hidden

@media screen and (max-width: 768px)
  .gallery
    padding 0

.button-to-cart-action
    margin-left: -12x !important
    margin-right: -12px !important
    // @media screen and (max-width: 768px)
    //   margin-left: 0 !important
    //   margin-right: 0 !important
    //   position: fixed
    //   z-index 50
    //   width: 100%
    //   bottom: 0
    //   left: 0
    //   background: #fff
    //   box-shadow: 1px 1px 2px 1px $color-gray-200;

.wrap-btn-action
  display: flex
  flex-wrap: wrap
  gap: 0.75rem

.wrap-btn-action-case
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 0.75rem
  @media screen and (min-width: 768px)
    flex-wrap: nowrap;
    gap: 1rem
  .btn-action-item
    flex-basis: 100%;
    @media screen and (min-width: 768px)
      flex-basis: 50%;
      &.full
        flex-basis: 100%;

@media screen and (max-width: 960px)
  .footer-margin
    margin-bottom: 150px;


.product-detail-layout
  display: flex
  justify-content: center
  flex-direction: column
  // margin-right: -12px
  // margin-left: -12px
  padding-top: 22px
  .pdp-preview-swiper
    // width: 50%
    width: 100%
    @media screen and (min-width: 48rem)
      width: 50%
    @media screen and (min-width: 61.875rem)
      padding-top: 0
      width: 63.5%
  .pdp-content
    width: 100%
    @media screen and (min-width: 768px)
        padding: 12px
        padding-left: 48px
        padding-right: 0px
    @media screen and (min-width: 48rem)
      width: 50%
    @media screen and (min-width: 61.875rem)
        width: 36.5%
        padding-left: 48px
  @media screen and (min-width: 48rem)
    flex-direction: row


.btn-deposit {
    padding: 0.75rem !important
    // padding: 1.25rem 0 !important
    font-weight: bold !important
    font-size: 0.875rem !important
    background: $color-deposit !important

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5
    }

}

.full
  .btn-deposit
    background: $color-bnn !important
    .text-deposit
      color: $color-bnn-btn-text !important
      font-size: 1rem !important
  .full-payment-content
    font-size: 1rem !important


.btn-bnn-default
  padding: 0.75rem !important

.text-deposit
    color: $color-deposit-text

.deposit-content,.full-payment-content
  width: 100%
  display: flex
  justify-content: center
  gap: 8px
  align-items: baseline;
  // padding: 0 2rem
  white-space: nowrap

.product-label-layout
  display: flex
  flex-wrap: auto

.action-contain-layout
  display flex
  width: 100%
  flex-direction: row
  justify-content: space-between
  flex-wrap: wrap
  padding: 0.5rem 0 2px 0
  .soldOut
    width: 100%
    padding: 12PX
  .deposit
    width: 50%
    padding: 12PX
    @media screen and (max-width: 960px)
      width: 100%
    @media small
      padding 4px 12px
  .full
    width: 50%
    padding: 12PX
    @media screen and (max-width: 960px)
      width: 100%
    @media small
      padding 4px 12px
  .full-employee
    width: 100%
    padding: 12PX
    cursor: pointer
    .text-employee
      font-weight: bold
    .text-payment
      margin-left: 0px
      font-weight: bold
      color: $color-bnn
  @media screen and (max-width: 960px)
    flex-direction: row
    align-items: center

.w-full
  width: 100% !important

.ship-date-summary-price-layout
  display flex
  justify-content: space-between
  width: 100%
  @media tablet
    flex-direction: row
    align-items: flex-start
    justify-content: space-between
    text-align: center
  align-items: flex-start
  .ship
    max-width: 100%
    color: $color-bnn-border-dark-btn
    .ship-date
      font-weight: bold
      color: $color-bnn-border-dark-btn
    @media tablet
      // padding: 0.5rem 0
      width: 100%

  .price
    color: $color-bnn-border-dark-btn
    @media tablet
      text-align: right
      padding: 0.5rem 0
      width: 100%

.desktop-only
  display: block
  @media tablet
    display: none

.html-content
  overflow: auto
  width: 100%
  min-width: 100%
  justify-content: center;

.hurry-up-label
  display: block

.model-container
  @media small
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap: 0 1rem

.campaign-name-content {
  @media small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: -100px
    padding 1rem 0;
  }
}

.cart-fixed {
  //@media small {
    position: fixed
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 50
    width: 100%
    bottom: 0
    left:0
    padding: 1rem


    background: #f2f2f2
    box-shadow: 1px 1px 2px 1px $color-gray-200;
  //}
}

.ship-date-row {
  margin-top: 0
  // @media small {
  //   margin-top: -18px
  // }
}

.model-wrapper {
  width: 100%

  @media small {
    // width: calc(100% + (1.5rem))
    // min-width: calc(100% + (1.5rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // padding-top: 1rem
    // background-color: $color-bnn-gray-light-2
  }
}

.color-wrapper{
  width: 100%

  @media small {
    // width: calc(100% + (1.75rem))
    // min-width: calc(100% + (1.75rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // background-color: $color-bnn-gray-light-2
  }
}

.capacity-wrapper {
  width: 100%

  @media small {
    // width: calc(100% + (1.75rem))
    // min-width: calc(100% + (1.75rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // background-color: $color-bnn-gray-light-2
    // padding-bottom: 1rem
  }
}

.is-mobile {
  display: none
  @media screen and (max-width: 600px) {
    display: block
  }
}

.is-desktop {
  display: block
  @media screen and (max-width: 600px) {
    display: none
  }
}

.title-also-bought {
  padding-top: 1rem
  font-weight: bold
  font-size: 1.25rem
}

.select-by-sku
  .btn-model.disabled
    color: $color-bnn-gray-medium-2
  .btn-model.disabled,
  .btn-color.disabled,
  .btn-spec.disabled
    event-pointer: none
    cursor: not-allowed

.v-btn--disabled.v-btn--outlined
  opacity 0.45
  border-color: $color-bnn !important
  color: $color-bnn-btn-text !important
  .text-deposit
    color: $color-bnn !important

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).btn-bnn-default
  opacity 0.45
  background-color: $color-bnn !important
  color: $color-bnn-btn-text !important


.pdp-content-container{
    display: flex;
    flex-direction: column;
    gap: 48px;
    max-width: 100dvw;
}
.badge-pre-order {
    color: #336e7b;
    font-weight: 600
}

::v-deep .v-btn.btn-link.promotion-privileges {
    padding 0 !important
    color: #bf4800
    cursor pointer

    &:hover {
        color: $color-bnn

        .v-icon {
            color: $color-bnn
        }
    }

    &:hover:before {
        background-color: transparent !important
    }

    &:focus {
        &:before {
            background-color: transparent !important
        }
    }

    &::after {
        background-color: transparent !important
    }

    .v-icon {
        color: #bf4800
    }
}
</style>
